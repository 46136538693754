exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-503-tsx": () => import("./../../../src/pages/503.tsx" /* webpackChunkName: "component---src-pages-503-tsx" */),
  "component---src-pages-agent-tsx": () => import("./../../../src/pages/agent.tsx" /* webpackChunkName: "component---src-pages-agent-tsx" */),
  "component---src-pages-applications-tsx": () => import("./../../../src/pages/applications.tsx" /* webpackChunkName: "component---src-pages-applications-tsx" */),
  "component---src-pages-control-tsx": () => import("./../../../src/pages/control.tsx" /* webpackChunkName: "component---src-pages-control-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-issues-tsx": () => import("./../../../src/pages/issues.tsx" /* webpackChunkName: "component---src-pages-issues-tsx" */),
  "component---src-pages-monitor-tsx": () => import("./../../../src/pages/monitor.tsx" /* webpackChunkName: "component---src-pages-monitor-tsx" */),
  "component---src-pages-queues-tsx": () => import("./../../../src/pages/queues.tsx" /* webpackChunkName: "component---src-pages-queues-tsx" */),
  "component---src-pages-task-tsx": () => import("./../../../src/pages/task.tsx" /* webpackChunkName: "component---src-pages-task-tsx" */),
  "component---src-pages-tasks-tsx": () => import("./../../../src/pages/tasks.tsx" /* webpackChunkName: "component---src-pages-tasks-tsx" */),
  "component---src-pages-workers-tsx": () => import("./../../../src/pages/workers.tsx" /* webpackChunkName: "component---src-pages-workers-tsx" */)
}

